<template>
    <div>
        <head-layout :head-btn-options="headBtnOptions" head-title="主子查询新增" @head-save="headSave('save')"
            @head-save-back="headSave('back')" @head-cancel="headCancel"></head-layout>
        <div style="background-color: white; height: calc(100vh - 100px);padding: 20px 10px 20px 0;">
            <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px">

                <el-row>

                    <el-col :span="8">
                        <el-form-item label="名称" prop="name">


                            <el-input v-model="form.name" placeholder="名称" autocomplete="off" clearable></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">

                        <el-form-item label='编码' prop="code">
                            <el-input v-model="form.code" placeholder='编码' autocomplete="off" clearable></el-input>
                        </el-form-item>
                    </el-col>


                    <el-col :span="8">

                        <el-form-item label='主服务' prop="marketApiId">
                            <el-select v-model="form.marketApiId" clearable  filterable remote reserve-keyword placeholder="请输入关键词"
                            @change="changeFid"
                                :remote-method="remoteMethod" :loading="loading">
                                <el-option v-for="item in marketList" :key="item.id" :label="item.apiName"
                                    :value="item.id">
                                </el-option>
                            </el-select>

                          
                        </el-form-item>
                    </el-col>

                </el-row>

                <el-row>


                    <el-col :span="8">

                        <el-form-item label='子服务' prop="childrenApiIdList">


                            <!-- <el-select v-model="form.childrenApiIdList" filterable placeholder="请选择" multiple clearable>
                                <el-option v-for="item in marketChildrenList" :key="item.id" :label="item.apiName"
                                    :value="item.id">
                                </el-option>
                            </el-select> -->


                            <el-select v-model="form.childrenApiIdList" clearable multiple  filterable remote reserve-keyword placeholder="请输入关键词"
                           
                                :remote-method="remoteMethod2" :loading="loading">
                                <el-option v-for="item in marketChildrenList" :key="item.id" :label="item.apiName"
                                    :value="item.id">
                                </el-option>
                            </el-select>



                        </el-form-item>
                    </el-col>


                </el-row>



            </el-form>
        </div>
    </div>
</template>
  
<script>
import {
    getMarketApiResultById,
    selectMarketApiLlist,
    tokenInitcode,
    saveMarketApiResult,
} from "@/api/dataAcquisition";
import FormLayout from "@/views/components/layout/form-layout";

export default {
    name: "dataCoonfigAdd",
    computed: {},
    data() {
        return {
            marketList: [],//主服务下拉
            marketChildrenList: [],//子服务下拉
            headBtnOptions: [
                {
                    label: this.$t("cip.cmn.btn.saveBtn"),
                    emit: "head-save",
                    type: "button",
                    icon: "",
                },
                {
                    label: this.$t("cip.cmn.btn.saveBackBtn"),
                    emit: "head-save-back",
                    type: "button",
                    icon: "",
                },
                {
                    label: this.$t("cip.cmn.btn.celBtn"),
                    emit: "head-cancel",
                    type: "button",
                    icon: "",
                },
            ],
            rules: {
                name: [
                    {
                        required: true,
                        message: "请填写名称",
                        trigger: "blur",
                    },
                ],

                code: [
                    {
                        required: true,
                        message: "请填写编码",
                        trigger: "blur",
                    },
                ],
                marketApiId: [
                    {
                        required: true,
                        message: "请选择主服务",
                        trigger: "change",
                    },
                ],
                childrenApiIdList: [
                    {
                        required: true,
                        message: "请选择子服务",
                        trigger: "change",
                    },
                ],
               

            },
            form: {
                code: "",
                name: "",
                marketApiId: "",
                childrenApiIdList: [],
                marketApiReqList: [],
            },
            formLabelWidth: "120px",
        };
    },
    components: {
        FormLayout,
    },
    created() {
        // this.getmarkList();
        if (this.$route.query.type == "add") {

        }

        if (this.$route.query.type == "edit") {
            getMarketApiResultById({ id: this.$route.query.id }).then(res => {
                this.form = res.data.data

                
                selectMarketApiLlist({apiName:res.data.data.marketApiName}).then(res => {
                this.marketList = res.data.data
            })
                //子服务下拉
                selectMarketApiLlist({id:res.data.data.marketApiId}).then(res => {
                this.marketChildrenList = res.data.data
            })
            })
        }
    },
    mounted() { },
    methods: {
        remoteMethod(e){
            selectMarketApiLlist({apiName:e}).then(res=>{
                this.marketList = res.data.data
            })
        //   console.log();
        },

        remoteMethod2(e){
            selectMarketApiLlist({apiName:e,id:this.form.marketApiId}).then(res=>{
                this.marketChildrenList = res.data.data
            })
        },


        getmarkList() {
            selectMarketApiLlist({}).then(res => {
                this.marketList = res.data.data
            })
        },

        changeFid(fid) {
            this.form.childrenApiIdList = []
            this.form.marketApiReqList = []
            let aa ={}

            let name = ""
            this.marketList.forEach(element => {
                if (fid == element.id) {
                    name = element.apiName
                    aa = element
                }
            });
            aa.reqParams.forEach(element => {
                this.form.marketApiReqList.push(element.paramName)
            });
            // reqParams  paramName
            selectMarketApiLlist({ id: fid, name }).then(res => {
                this.marketChildrenList = res.data.data
            })
        },


        headSave(type) {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    saveMarketApiResult(this.form).then(res => {
                        this.$message.success(this.$t('cip.dc.Token.msg'));
                        if (type == "back") {
                            this.$router.$avueRouter.closeTag();
                            this.$router.back();
                        }
                    })
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        headCancel() {
            this.$router.$avueRouter.closeTag();
            this.$router.back();
        },
        
        
    
       

        
    },
};
</script>
  
<style scoped>
/* /deep/.el-input__inner{
    width: 200px;
   } */
</style>
  